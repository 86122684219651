import { useNavigate } from 'react-router-dom'

export default function Nav({ authenticated, onLogout }) {
  const navigate = useNavigate()

  if (!authenticated) {
    return null // Return nothing if the user is not authenticated
  }

  const handleLogout = async () => {
    onLogout() // invoke the prop callback
    navigate('/') // Navigate to the landing page
  }

  return (
    <nav className="navbar navbar-light bg-light justify-content-between center-content mx-2 px-2">
      <a className="navbar-brand" href="/">
        runBPM
      </a>
      <form className="form-inline">
        <button
          className="btn btn-dark my-2 my-sm-0"
          type="button"
          onClick={handleLogout}
        >
          Logout
        </button>
      </form>
    </nav>
  )
}
