import React from 'react'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Interface from './Components/Interface'
import Landing from './Components/Landing'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import PrivateRoute from './Components/PrivateRoute'
import Layout from './Components/Layout'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Navigate to="/interface" />} />
          <Route path="/login" element={<Landing />} />
          <Route element={<PrivateRoute />}>
            <Route path="/interface" element={<Interface />} />
          </Route>

          {/* Add a catch-all route for 404 */}
          <Route path="*" element={<div>404 Not Found</div>} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
