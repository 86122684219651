// Interface.js
import React, { useEffect, useState } from 'react'
import BpmSlider from './BpmSlider'
import GenreSelector from './GenreSelector'
import DummyList from './DummyList'
import { useRecommendations } from './GetRecommendations'
import { PlaylistTitle } from './PlaylistTitle'
import axios from 'axios'

function Interface() {
  const [bpm, setBpm] = useState(170)
  const [genres, setGenres] = useState([])
  const [recommendationsFetched, setRecommendationsFetched] = useState(false)
  const [showErrorMessage, setshowErrorMessage] = useState(false)
  const [title, setTitle] = useState('🏃 runBPM: ')

  const { recommendations, loading, getRecommendations } = useRecommendations()

  const getTitle = async () => {
    if (genres.length === 0) {
      return // Exit early
    }
    try {
      const url = `/playlist-title`
      const response = await axios.post(url, {
        recommendations: recommendations.map(({ songTitle, artist }) => ({
          songTitle,
          artist,
        })),
      })
      setTitle(response.data)
    } catch (error) {
      console.error('Error fetching recommendations:', error)
    }
  }

  useEffect(() => {
    getTitle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendations.map(({ songId }) => songId).join('-')])

  const {
    isEditingTitle,
    playlistTitle,
    titleInputRef,
    handleTitleEdit,
    handleTitleChange,
    handleTitleBlur,
  } = PlaylistTitle(title)

  const handleGetRecommendations = async () => {
    if (genres.length === 0) {
      setshowErrorMessage(true)
      return // Exit early
    }
    setshowErrorMessage(false)
    getRecommendations(bpm, genres)
    setRecommendationsFetched(true)
  }

  const handleSavePlaylist = async () => {
    try {
      const response = await axios.post('/create-playlist', {
        tracks: recommendations,
        title: playlistTitle,
      })
      if (response.status === 200) {
        alert('Playlist created successfully!')
      } else {
        alert('Error creating playlist. Please try again.')
      }
    } catch (error) {
      console.error('Error saving playlist:', error)
      alert('Error creating playlist. Please try again.')
    }
  }

  return (
    <div>
      {/* <h1>{title}</h1> */}

      <GenreSelector onGenreSelect={setGenres} />

      <BpmSlider bpm={bpm} setBpm={setBpm} />

      {playlistTitle && (
        <div className="mt-2 mx-2 px-2">
          <div
            onClick={handleTitleEdit}
            style={{ borderBottom: '1px dashed #000', cursor: 'pointer' }}
          >
            {isEditingTitle ? (
              <input
                ref={titleInputRef}
                value={playlistTitle}
                onChange={handleTitleChange}
                onBlur={handleTitleBlur}
                style={{
                  fontSize: 'larger',
                  border: 'none',
                  background: 'transparent',
                  width: '100%',
                }}
              />
            ) : (
              <span style={{ fontSize: 'larger' }}>{playlistTitle}</span>
            )}
          </div>
        </div>
      )}

      <div className="d-flex my-2 text-center mx-2 px-2">
        <button onClick={handleGetRecommendations} className="btn btn-primary">
          Get Recommendations
        </button>
        {recommendationsFetched && (
          <button
            onClick={handleSavePlaylist}
            className="btn btn-primary ml-2 mx-2 px-2"
          >
            Save Playlist in Spotify
          </button>
        )}
        <div className="d-flex align-items-center ml-auto">
          <small className="mr-2">powered by</small>{' '}
          {/* Added margin-right for space */}
          <img
            src="./Spotify_Logo.png"
            alt="Spotify Logo"
            style={{ height: '100%', maxHeight: '36px' }}
          />
        </div>
      </div>

      {showErrorMessage && (
        <div className="mt-2 mx-2 px-2" style={{ color: 'red' }}>
          First select some genres to get personalised recommendations for your
          next run! 😎
        </div>
      )}

      {loading ? (
        <p className="px-2 mx-2 px-2">Loading recommendations...</p>
      ) : (
        <DummyList recommendations={recommendations} />
      )}
    </div>
  )
}

export default Interface
