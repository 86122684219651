import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'

function Landing() {
  const handleLoginClick = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/spotify`
  }

  const bgStyle = {
    backgroundImage:
      'url(https://images.unsplash.com/photo-1590333748338-d629e4564ad9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2149&q=80)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  return (
    <div style={bgStyle}>
      <div className="container text-center">
        <div className="jumbotron bg-gradient-primary text-black shadow-lg">
          <h1 className="display-4 mb-4">Welcome to runBPM</h1>
          <p className="lead">
            Run in harmony with your favorite tracks! We curate your ideal
            running playlist based on your Spotify listening habits, desired
            cadence and genre preferences.
          </p>
          {/* <hr className="my-4" /> */}
          {/* <p>
            Select up to 5 preferred genres and your desired BPM. Let runBPM
            curate the best tracks to match your running cadence and elevate
            your running experience!
          </p> */}
          <button
            onClick={handleLoginClick}
            className="btn btn-light btn-lg mt-3"
            style={{ backgroundColor: '#1DB954', color: 'white' }}
          >
            Log in with Spotify
          </button>
        </div>
      </div>
    </div>
  )
}

export default Landing
