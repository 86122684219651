import React from 'react'

const SpotifyTempoPicker = ({ bpm, setBpm }) => {
  return (
    <div className="mx-2 px-2">
      {' '}
      {/* Full width container with no padding */}
      <div className="row">
        <div className="col-12 mx-2 px-2">
          {' '}
          {/* Full width column with no padding */}
          <input
            className="w-100"
            type="range"
            min="140"
            max="200"
            value={bpm}
            onChange={(e) => setBpm(e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 text-center">
          {' '}
          {/* Centered text for the label */}
          BPM: {bpm}
        </div>
      </div>
    </div>
  )
}

export default SpotifyTempoPicker
