import { useState, useEffect } from 'react'
import axios from 'axios'

export const useAuthentication = () => {
  const [loading, setLoading] = useState(true)
  const [authenticated, setAuthenticated] = useState(false)

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axios.get(`/is-authenticated`)
        if (response.data.authenticated) {
          setAuthenticated(true)
        }
        setLoading(false)
      } catch (error) {
        console.error('Error checking authentication status:', error)
      }
    }

    checkAuthentication()
  }, [])

  const logout = async () => {
    try {
      await axios.get(`/logout`)
      setAuthenticated(false)
      document.cookie.split(';').forEach((c) => {
        document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
      })
    } catch (error) {
      console.error('Error logging out:', error)
    }
  }

  return { authenticated, loading, logout }
}
