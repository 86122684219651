import React from 'react'

function DummyList({ recommendations = [] }) {
  // Ensure recommendations is always an array
  const validRecommendations = Array.isArray(recommendations)
    ? recommendations
    : []

  // Render if there are no recommendations
  if (validRecommendations.length === 0) {
    return (
      <div>
        {/* Choose your desired genres and BPM before loading recommendations. */}
      </div>
    )
  }

  return (
    <div className="py-2 center-content mx-2 px-2">
      {validRecommendations.map((track) => (
        <div key={track.songId} className="d-flex align-items-center my-2">
          <img
            src={track.albumArt}
            alt={track.songTitle}
            className="mr-3"
            style={{ width: '50px', height: '50px' }}
          />
          <div className="flex-grow-1">
            <div>{track.songTitle}</div>
            <small className="text-muted">by {track.artist}</small>
          </div>
          <div className="ml-3">BPM: {Math.round(track.bpm)}</div>
        </div>
      ))}
    </div>
  )
}

export default DummyList
