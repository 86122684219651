import { Navigate, Outlet } from 'react-router-dom'
import { useAuthentication } from './Authentication'

export default function PrivateRoute() {
  const { authenticated, loading } = useAuthentication()

  if (loading) {
    return 'Loading...'
  }

  if (authenticated) {
    return <Outlet />
  }

  return <Navigate to="/login" />
}
