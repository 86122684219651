import { useState, useEffect, useRef } from 'react'

export const PlaylistTitle = (initialTitle) => {
  const [isEditingTitle, setIsEditingTitle] = useState(false)
  const [playlistTitle, setPlaylistTitle] = useState(initialTitle)
  const titleInputRef = useRef(null)

  useEffect(() => {
    if (isEditingTitle) {
      titleInputRef.current.focus()
    }
  }, [isEditingTitle])

  // This useEffect is for syncing the playlistTitle with the initialTitle
  useEffect(() => {
    setPlaylistTitle(initialTitle)
  }, [initialTitle])

  const handleTitleEdit = () => setIsEditingTitle(true)
  const handleTitleChange = (e) => setPlaylistTitle(e.target.value)
  const handleTitleBlur = () => setIsEditingTitle(false)

  return {
    isEditingTitle,
    playlistTitle,
    titleInputRef,
    handleTitleEdit,
    handleTitleChange,
    handleTitleBlur,
  }
}
