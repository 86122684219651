import { Outlet } from 'react-router-dom'
import Nav from './Navbar'
import { useAuthentication } from './Authentication'

export default function Layout() {
  const { authenticated, logout } = useAuthentication()
  return (
    <div className="">
      <Nav authenticated={authenticated} onLogout={logout} />
      <Outlet />
    </div>
  )
}
