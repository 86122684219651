import { useState } from 'react'
import axios from 'axios'

export const useRecommendations = () => {
  const [recommendations, setRecommendations] = useState([])
  const [loading, setLoading] = useState(false)

  const getRecommendations = async (bpm, genres) => {
    if (genres.length === 0) {
      return // Exit early
    }
    try {
      setLoading(true)
      const response = await axios.get(`/recommendations`, {
        params: {
          limit: 50,
          target_tempo: bpm,
          seed_genres: genres.join(','),
        },
      })
      setRecommendations(response.data)
    } catch (error) {
      console.error('Error fetching recommendations:', error)
    } finally {
      setLoading(false)
    }
  }

  return { recommendations, loading, getRecommendations }
}
